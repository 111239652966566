define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/user", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @ctx.user}}
    <a
      href="{{@ctx.baseuri}}/u/{{@ctx.user.username}}/activity"
      data-user-card={{@ctx.user.username}}
    >
      {{avatar @ctx.user imageSize="tiny"}}
      {{@ctx.user.username}}
    </a>
  {{else}}
    {{@ctx.id}}
  {{/if}}
  */
  {
    "id": "vYgS4yPl",
    "block": "[[[41,[30,1,[\"user\"]],[[[1,\"  \"],[10,3],[15,6,[29,[[30,1,[\"baseuri\"]],\"/u/\",[30,1,[\"user\",\"username\"]],\"/activity\"]]],[15,\"data-user-card\",[30,1,[\"user\",\"username\"]]],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,1,[\"user\"]]],[[\"imageSize\"],[\"tiny\"]]]],[1,\"\\n    \"],[1,[30,1,[\"user\",\"username\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,1,[\"id\"]]],[1,\"\\n\"]],[]]]],[\"@ctx\"],false,[\"if\",\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/user.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "user"));
});