define("discourse/plugins/discourse-data-explorer/discourse/templates/group-reports-show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="user-content">
    <h1>{{this.model.name}}</h1>
    <p>{{this.model.description}}</p>
  
    <form class="query-run" {{on "submit" this.run}}>
      {{#if this.hasParams}}
        <ParamInputForm
          @initialValues={{this.parsedParams}}
          @paramInfo={{this.model.param_info}}
          @onRegisterApi={{this.onRegisterApi}}
        />
      {{/if}}
  
      <DButton
        @action={{this.run}}
        @icon="play"
        @label="explorer.run"
        @type="submit"
        class="btn-primary"
      />
  
      <DButton
        @action={{this.toggleBookmark}}
        @label={{this.bookmarkLabel}}
        @icon={{this.bookmarkIcon}}
        class={{this.bookmarkClassName}}
      />
    </form>
  
    <ConditionalLoadingSpinner @condition={{this.loading}} />
  
    {{#if this.results}}
      <div class="query-results">
        {{#if this.showResults}}
          <QueryResult
            @query={{this.model}}
            @content={{this.results}}
            @group={{this.group}}
          />
        {{else}}
          {{#each this.results.errors as |err|}}
            <pre class="query-error"><code>{{~err}}</code></pre>
          {{/each}}
        {{/if}}
      </div>
    {{/if}}
  </section>
  */
  {
    "id": "ZIPiEr7U",
    "block": "[[[10,\"section\"],[14,0,\"user-content\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[30,0,[\"model\",\"name\"]]],[13],[1,\"\\n  \"],[10,2],[12],[1,[30,0,[\"model\",\"description\"]]],[13],[1,\"\\n\\n  \"],[11,\"form\"],[24,0,\"query-run\"],[4,[38,0],[\"submit\",[30,0,[\"run\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"hasParams\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@initialValues\",\"@paramInfo\",\"@onRegisterApi\"],[[30,0,[\"parsedParams\"]],[30,0,[\"model\",\"param_info\"]],[30,0,[\"onRegisterApi\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\",\"@type\"],[[30,0,[\"run\"]],\"play\",\"explorer.run\",\"submit\"]],null],[1,\"\\n\\n    \"],[8,[39,3],[[16,0,[30,0,[\"bookmarkClassName\"]]]],[[\"@action\",\"@label\",\"@icon\"],[[30,0,[\"toggleBookmark\"]],[30,0,[\"bookmarkLabel\"]],[30,0,[\"bookmarkIcon\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,4],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"results\"]],[[[1,\"    \"],[10,0],[14,0,\"query-results\"],[12],[1,\"\\n\"],[41,[30,0,[\"showResults\"]],[[[1,\"        \"],[8,[39,5],null,[[\"@query\",\"@content\",\"@group\"],[[30,0,[\"model\"]],[30,0,[\"results\"]],[30,0,[\"group\"]]]],null],[1,\"\\n\"]],[]],[[[42,[28,[37,7],[[28,[37,7],[[30,0,[\"results\",\"errors\"]]],null]],null],null,[[[1,\"          \"],[10,\"pre\"],[14,0,\"query-error\"],[12],[10,\"code\"],[12],[1,[30,1]],[13],[13],[1,\"\\n\"]],[1]],null]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"err\"],false,[\"on\",\"if\",\"param-input-form\",\"d-button\",\"conditional-loading-spinner\",\"query-result\",\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/templates/group-reports-show.hbs",
    "isStrictMode": false
  });
});