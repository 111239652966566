define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/url", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a href={{@ctx.href}}>{{@ctx.target}}</a>
  */
  {
    "id": "tVGKiwpM",
    "block": "[[[10,3],[15,6,[30,1,[\"href\"]]],[12],[1,[30,1,[\"target\"]]],[13]],[\"@ctx\"],false,[]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/url.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "url"));
});