define("discourse/plugins/discourse-data-explorer/discourse/components/result-types/group", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @ctx.group}}
    <a href="{{@ctx.baseuri}}/groups/{{@ctx.group.name}}">{{@ctx.group.name}}</a>
  {{else}}
    {{@ctx.id}}
  {{/if}}
  */
  {
    "id": "AjwfIfNJ",
    "block": "[[[41,[30,1,[\"group\"]],[[[1,\"  \"],[10,3],[15,6,[29,[[30,1,[\"baseuri\"]],\"/groups/\",[30,1,[\"group\",\"name\"]]]]],[12],[1,[30,1,[\"group\",\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,1,[\"id\"]]],[1,\"\\n\"]],[]]]],[\"@ctx\"],false,[\"if\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/result-types/group.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "group"));
});